/** --------------------------------------------------
Author       : Dreambuzz
Template Name: Rapoo - it solution landing template
Author URI : https://themeforest.net/user/dreambuzz/portfolio
Description: Rapoo,it, onepage,clean,bootstrap4, 
Description: Rapoo,it, onepage,clean,bootstrap4, responsive,modern,agency,landing template.
Version      : 1.0
* -------------------------------------------------  */

/*-----------------------------
 CSS INDEXING
* --------------------------------------
* 01.GLOBAL CSS STYLE
* 02.TYPOGRAPHY CSS STYLE
* 03.BUTTONS STYLE
* 04.HOMEPAGE CSS STYLE
    -LOGO CSS
    -MENU CSS
    -BACKGROUND
* 05.FEATURES CSS STYLE
* 06.SERVICE CSS STYLE
* 07.ABOUT CSS STYLE
* 08.PRICING CSS STYLE
* 09.TESTIMONIAL STYLE
* 10.LATEST BLOG CSS STYLE
* 11.CONTACT CSS STYLE
* 12.FOOTER CSS STYLE
* ----------------------------------------
*/


@import url('https://fonts.googleapis.com/css?family=Muli:300,400,500,700,900');



html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
}

html, body {
    height: 100%;
  }

body {
    display: flex;
    flex-direction: column;   
    font-family: 'Muli', sans-serif;
    font-size: 15px;
    line-height: 1.75;
    margin: 0;
    text-align: left;
    background-color: #fff;
    font-weight: 400;
    color: #6c757d;
}

/*--------------------
 TYPOGRAPHY
-----------------*/

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Muli', sans-serif;
    font-weight: 700;
    line-height: 1.35;
    margin-bottom: .5rem;
}

.h1,
h1 {
    font-size: 2.125rem;
    line-height: 40px;
}

.h2,
h2 {
    font-size: 1.625rem;
}

.h3,
h3 {
    font-size: 1.375rem;
}

.h4,
h4 {
    font-size: 1.125rem;
}

.h5,
h5 {
    font-size: .9375rem;
}

.h6,
h6 {
    font-size: .75rem;
}

.lead {
    font-size: 1.0625rem;
    font-weight: 300;
}

.font-weight-500 {
    font-weight: 500 !important;
}

.section-title {
    font-size: 35px;
    font-weight: 700;
    text-transform: capitalize;
    margin-bottom: 15px;
}


@media (max-width:767.98px) {
    h1 {
        font-size: 1.5rem
    }

    h2 {
        font-size: 1.375rem
    }

    h3 {
        font-size: 1.25rem
    }

    h4 {
        font-size: 1.125rem
    }

    h5 {
        font-size: .9375rem
    }

    h6 {
        font-size: .75rem
    }
}


p {
    margin-bottom: 0px;
    font-size: 15px;
}

a{
    color: #53C279;
}
a:hover,a:focus{
    outline: none;
    text-decoration:  none;
}

.mb-6{
    margin-bottom: 2rem !important;
}

.section-heading{
    margin-bottom: 80px;
}
.section-heading p{
    margin-bottom: 0px!important;
}

/*--------------------

-----------------*/


img {
    vertical-align: middle;
    border-style: none;

}

.img-thumbnail {
    padding: .25rem;
    border: 1px solid #dee2e6;
    background-color: #fff
}


.section {
    position: relative;
    padding: 3.125rem 0;
}

@media (min-width:768px) {
    .section {
        padding: 3.5rem 0
    }
}

.section-top {
    padding-top: 3.125rem;
}

.section-bottom {
    padding-bottom: 3.125rem;
}

@media (min-width:768px) {
    .section-top {
        padding-top: 10.3125rem
    }
}


.bg-grey {
    background: #F4F7FC;
}

/*-------------------
  BUTTONS STYLE
----------------=----*/

.btn {
    font-size: .6875rem;
    font-weight: 700;
    position: relative;
    text-transform: uppercase;
    padding: 0.6rem 0.6rem;
    border: 0.1rem solid transparent;
    transition: all 0.2s ease-in-out;
    letter-spacing: .5px
}



.btn-circle {
    display: inline-flex;
    width: 4.20313rem !important;
    height: 4.20313rem !important;
    padding: 0 !important;
    border-radius: 50%;
    align-items: center;
    justify-content: center
}


.btn-outline-dark {
    border: 1px solid#222;
}
.btn-white{
    background: #fff;
    color: #212529;
}
.btn-trans-white {
    border: 1px solid#fff;
    color: #fff;
}

.btn-outline-dark:hover {
    background: #222 !important;
    color: #fff !important;
}

.btn.btn-link {
    color: #212529;
}

.btn:focus {
    box-shadow: none !important
}

.btn-circled {
    border-radius: 75px;
}


.bg-primary,.btn-primary,.btn-white:hover{
    background:#53C279!important;
    border-color: #53C279!important;
    color: #fff;
}

.bg-dark,.btn-primary:hover{
    background: #232323!important;
    border-color: #232323!important;
    color: #fff;
}

/*-------------------
   MENU
----------------------*/

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    content: "";
    vertical-align: middle;
    background: no-repeat 50%;
    background-size: 100% 100%
}

.main-nav {
    margin-bottom: -45px;
    z-index: 999;
}

.navbar-nav .nav-link {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.navbar-dark .navbar-nav .nav-link {
    color: #fff;
    font-weight: 700;
    letter-spacing: .5px;
    text-transform: uppercase;
}

.navbar {
    position: relative;
    padding: 1rem 1rem;
    border-radius: 5px;
}

.fixed-top {
    position: fixed;
    z-index: 1030;
    right: 0;
    left: 0;
}

.navbar:before {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: "";
    transition: opacity .15s linear;
    opacity: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    background-color: #fff;

}

.navbar-dark {
    color: #fff;
}

.navbar-light::before {
    opacity: 1;
}

.navbar-light {
    color: #212529;
}


.navbar .nav-item-divider {
    display: none;
}

@media (min-width:1200px) {
    .navbar .nav-item-divider {
        display: block
    }
}

.navbar .nav-item-divider .nav-link {
    opacity: .25
}

.navbar .nav-item-divider .nav-link > span {
    border-left: 1px solid
}

@media (max-width:1199.98px) {
    .navbar .dropdown-menu {
        padding-top: 0;
        padding-bottom: 0;
        border: 0
    }
}

@media (min-width:1200px) {
    .navbar .dropdown-menu {
        transform: translateY(.5rem);
        opacity: 0
    }

    .navbar .dropdown-menu.show {
        animation: .2s forwards d
    }

    @keyframes d {
        to {
            transform: translateY(0);
            opacity: 1
        }
    }
}



.nav-item.dropdown .dropdown-menu{
    -webkit-transition: all 300ms ease;
    -o-transition:all 300ms ease;
    transition: all 300ms ease;
    display: block;
    visibility: hidden;
    opacity: 0;
    top: 120%;
    -webkit-box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
    box-shadow: 2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
    border: 0px;
    padding: 0px;
    position: absolute;
}

.nav-item.dropdown:hover .dropdown-menu{
    opacity: 1;
    visibility: visible;
    top: 100%;
}


.dropdown-item:hover {
    color: #fff;
    text-decoration: none;
    background-color: #53C279;

}
.header-bar.fixed-header .main-navigation {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background: rgba(20,27,40,.90);
    border-color: transparent;

}


/*-------------------
  DROPDOWN MENU
----------------------*/


.dropdown-menu {
    opacity: 0
}

.dropdown-menu.show {
    animation: .2s forwards b
}

@keyframes b {
    to {
        opacity: 1
    }
}

.dropdown-item {
    font-size: .6875rem;
    font-weight: 500;
    padding-top: .5rem;
    padding-bottom: .5rem;
    transition: background-color .1s;
    text-transform: uppercase
}

@media (min-width:1200px) {
    .dropdown-item {
        padding-top: .75rem;
        padding-bottom: .75rem
    }
}

@media (min-width:1200px) {
    .dropdown-item + .dropdown-item {
        border-top: 1px solid rgba(0, 0, 0, .1)
    }
}








/*------------------------
 BACKGROUND
----------------------------*/


.bg-cover {
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: cover
}

.bg-cover,
.bg-overlay,
.bg-overlay:before {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0
}

.bg-overlay:before {
    content: "";
    opacity: .55;
    background-color: #212529
}

.box-shadow {
    box-shadow: 0 25px 100px -5px rgba(0, 0, 0, .15)
}



/*----------------------
 HEADER STYLE
-----------------------*/

.header-socials {
    line-height: 22px;
    margin-bottom: 0px;
}

.header-socials li {
    padding: 8px;
}

.header-socials li a{
    color: #53C279;
}


/*-----------------
 LOGO BAR
-------------------*/

.logo-bar {
    padding: 30px 0px 60px;
}

.top-info-block {
    margin-right: 30px;
}


.icon-block {
    margin-right: 20px;
    color:#53C279;
    font-size: 40px;
    line-height: 20px;
    font-weight: 400;
}

.top-info-block h5,
.top-info-block p {
    margin-bottom: 0px;
}

.top-info-block:last-child {
    margin-right: 0px;
}

.header-bar {
    overflow: visible;
    position: relative;
    padding-bottom: 30px;
}


.main-navigation {
    margin-top: -40px;
}



/*----------------------
 BANNER STYLE
-----------------------*/

.py-7 {
    padding: 7.5rem 0;
}

.py-8 {
    padding: 8.5rem 0;
}


.main-banner h5{
    margin-bottom: 20px;
}

.main-banner h1.display-4{
    line-height: 65px;
}

.main-banner p.lead,
.banner-content p.lead{
    margin-bottom: 2.5rem!important;
}


/*----------------------
 FEATURE STYLE
-----------------------*/
.img-icon-block {
    position: relative;
}

.img-icon-block:before {
    position: absolute;
    content: "";
    top: -27px;
    background-color: #53C279;
    opacity: .3;
    width: 80px;
    right: 70px;
    height: 80px;
    border-radius: 50%;
    z-index: 1;
}

.img-icon-block i{
    font-size: 58px;
    z-index: 2!important;
    color: #333;
    position: relative;
}

/*----------------------
 SERVICE
-----------------------*/
h5.subtitle {
    color: #53C279;
	margin-bottom: 10px;
	font-weight: 300;
}

.service-heading{
    margin-bottom: 50px;
}

.service-img {
    margin-bottom: -200px;
    border: 10px solid#fff;
    z-index: 9999;
}

.service-content {
    padding-top: 60px;
    padding-left: 15px;
}

.service-icon {
    margin-right: 20px;
    color: #fff;
    font-size: 25px;
    width: 50px;
    height: 50px;
    background: #53C279;
    text-align: center;
    border-radius: 50%;
    padding-top: 5px;
}

.service-block {
    margin-bottom: 50px;
}
.service-content h1{
    margin-bottom: 20px;
    margin-top: 15px;
}



.mb-30{
    margin-bottom: 30px;
}
.service-inner-content h4{
    text-transform: uppercase;
}


/*----------------------
 PROCESS
-----------------------*/
.process-block {
    text-align: center;
}

.process-block img {
    border-radius: 5px;
    margin-bottom: 30px;
}

.process-block h3 {
    margin-bottom:15px;
}

/*----------------------
 WEB SERVICES
-----------------------*/

#services-2:before{
    position: absolute;
    content:"";
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(30deg, rgba(121, 110, 255, 0.95) 0%, rgba(33, 200, 122, 0.95) 100%);
}
.web-service-block {
    text-align: center;
    padding: 35px 25px;
    -webkit-transition: all .5s ease-in-out;
    -o-transition: all .5s ease-in-out;
    transition: all .5s ease-in-out;
    border-radius: 10px;
    border: 1px solid rgba(255,255,255,0.05);
    margin-bottom: 10px;
}

.web-service-block i {
    font-size: 70px;
    margin-bottom: 30px;
    display: block;
}

.web-service-block i,
.web-service-block h3 {
    color: #fff;
}

.web-service-block p {
    color: #eee;
    margin-top: 15px;
}

.web-service-block:hover {
    background: #fff;
    border-color: transparent;
}

.web-service-block:hover i {
    color: #53C279;
}

.web-service-block:hover h3 {
    color: #111;
}

.web-service-block:hover p {
    color: #111;
}




/*----------------------
 PRICING
-----------------------*/

.price-block {
    margin: 20px 0px 30px;
}

.price-block h2 {
    font-size: 80px;
    font-weight: 700;
    position: relative;
    display: inline-block;
}


.price-block h2 small {
    position: absolute;
    top: 20px;
    font-size: 16px;
    left: -9px;
}

.price-block h6 {
    font-size: 14px;
    border-top: 1px solid#eee;
    border-bottom: 1px solid#eee;
    padding: 7px;
    text-transform: capitalize;
    width: 50%;
    margin: 10px auto;

}

.price-features {
    margin: 30px 0px 50px;
}

.price-features li {
    font-size: 16px;
    padding: 5px 0px;
    position: relative;
    padding-left: 30px;
}
.price-features li:before{
    position: absolute;
    content:"\f05d";
    font-family: "FontAwesome";
    left: 0px;
    top: 5px;
    color: #53C279;
}

.pricing-block .price-features li{
    padding-left: 0px;
}
.pricing-block .price-features li:before{
    display: none;
}

/*----------------------
 PRoject
-----------------------*/


.overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, .92);
}

.overlay.feature-overlay {
    background-image: linear-gradient(30deg, rgba(121, 110, 255, 0.95) 0%, rgba(33, 200, 122, 0.95) 100%);
    opacity: .9;
}

#projects-wrap p.lead{
    color: #a9a9a9!important;
}
#projects {
    margin-top: -60px;
}

.single-project img {
    border-radius: 10px;
}

.project-content {
    text-align: center;
    padding: 40px 20px;
    position: relative;
}

.project-content .btn {
    padding: .7rem 1.7rem;
    border-radius: 25px;
}

.project-content p {
   margin-top: 20px;
}

.project-content h4 {
    text-transform: capitalize;
}

.project-content-block {
    padding: 70px 40px;
    margin-left: -120px;
    background: #F4F7FC;
    position: absolute;
    bottom: -70px;
    border: 15px solid#fff;
    -webkit-box-shadow: 0 7px 22px rgba(0, 0, 0, 0.08);
    box-shadow: 0 7px 22px rgba(0, 0, 0, 0.08);
}

.project-content-block h4 {
    color: #53C279;
}

.project-content-block p {
    margin-top: 25px;
}
/*------------------------------------
SECTION TESTIMONIAL
-------------------------------------*/
#section-testimonial{
    padding-bottom: 120px;
    background: #F9FAFF;
}
.mb25{
    margin-bottom: 25px;
}
.test-inner{
    position: relative;
    padding: 30px;
    background: #fff;
    border-top-right-radius: 35px;
    margin-bottom: 25px;
    -webkit-transition: all .7s ease;
    -o-transition:all .7s ease ;
    transition:all .7s ease ;
}

.test-inner:hover{
     -webkit-box-shadow: 0 7px 22px rgba(0, 0, 0, 0.08);
    box-shadow:0 7px 22px rgba(0, 0, 0, 0.08) ;
}

.test-author-thumb{
    margin-bottom: 15px;
}
.test-author-thumb img{
    width: 90px;
    height: 90px;
    border-radius: 100%;
    border: 1px dotted #ddd;
    padding: 5px;
}

.test-author-info{
    margin-left: 20px;
    margin-top: 20px;
}

.test-inner i{
    font-size: 60px;
    opacity: .09;
    position: absolute;
    right: 40px;
    bottom: 20px;
}








/*----------------------
 BLOG SECTION
-----------------------*/
.blog-box {
    position: relative;
}



.blog-box .blog-img-box img {
    border-radius: 5px;
    box-shadow: 0 7px 12px rgba(0, 0, 0, 0.08);
}

.single-blog {
    padding-top: 25px;
    position: relative;
}

.blog-content ul {
    margin-bottom: 10px;
}



.blog-content a h3 {
    transition: all .3s ease 0s;
    margin: 15px 0px;
}

.blog-content:hover a h3 {
    color: #53C279;
}


.bg-light .single-blog{
    padding: 40px;
    text-align: center;
    border-bottom: 1px solid#eee;
}



/*----------------------
 FOOTER
-----------------------*/
.content {
    flex: 1 0 auto;
}
.footer {
    flex-shrink: 0;
 }

#footer {
    padding: 80px 0px;
    position: relative;
    background: #1e212a;
}


.footer-overlay {
    background: #111;
    opacity: .94;
}

.footer-brand {
    font-size: 30px;
}

.footer-links {
    padding-left: 0;
    list-style-type: none
}


.footer-widget p {
    color: #888;
    margin-top: 20px;
    margin-bottom: 20px;
    line-height: 32px;
    font-size: 14px;
}

.footer-widget h3 {
    color: #fff;
    margin-bottom: 30px;
}


.footer-links a {
    color: #888;
    line-height: 38px;
    font-size: 14px;
    text-transform: Capitalize;
}

.footer-links i{
    width: 25px;
}

.footer-copy {
    color:#888;
    font-size: 16px;
    position: relative;
}

.current-year {
    color: #53C279 !important;
}

.footer-copy:before {
    position: absolute;
    content: "";
    left: 0px;
    top: 12px;
    width: 66%;
    height: 1px;
    background: #94959a;
}



/*-----------------------------
 SCROLL TO TOP
--------------------------------*/

.scroll-to-top{
    position: fixed;
    content: "";
    right: 30px;
    bottom: 50px;
    z-index: 999;
    background: #53C279;
    color: #fff!important;
    border-radius: 100%;
    opacity: 0;
    
}

.scroll-to-top i{
    width: 60px;
    height: 60px;
    border-radius: 100%;
    text-align: center;
    font-size: 22px;
    line-height: 60px;
    display: block;
     -webkit-box-shadow: 0 7px 22px rgba(0, 0, 0, 0.08);
    box-shadow:0 7px 22px rgba(0, 0, 0, 0.08) ;
}

.scroll-to-top:hover, .scroll-to-top:focus{
    text-decoration: none;
}

.reveal{
    transition: all .3s;
    cursor: pointer;
    opacity: 1;
}


/*-------------------------
 HOME -2 
-----------------------------*/

.banner-content {
    padding: 150px 0px;
    padding-right: 30px;
}
.banner-content p{
    color: #eee;
    padding-right: 80px;
    margin-bottom: 30px !important;
}
.banner-content .display-4{
    color: #fff;
    font-weight: 600;
}

#banner .overlay{
    opacity: .7;
}

.banner-contact-form{
    padding:45px 40px;
    margin-top: 30px;
}
.banner-contact-form .form-control{
    border-radius: 0px;
    height: 45px;
}
.banner-contact-form textarea.form-control{
    height: auto;
}

/*--------------------------
ABOUT
---------------------------*/
#about .img-block{
    position: relative;
      -webkit-box-shadow: 0 7px 22px rgba(0, 0, 0, 0.08);
    box-shadow: 0 7px 22px rgba(0, 0, 0, 0.08);
}
#about .img-block img{
    border: 5px solid #53C279;
}
.pt-6{
    padding-top: 4.5rem;
}
.img-icon i{
    font-size: 60px;
}

/*--------------------------
PRICING
---------------------------*/
.pricing-box{
    border: 1px solid#eee;
    padding: 60px;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition:all 300ms ease-in-out ;
    transition:all 300ms ease-in-out ;
}

.pricing-box .price-block h2 span{
    font-size: 12px;
    font-weight: normal;
    text-transform: capitalize;
}
.pricing-box:hover .price-block h2 ,.pricing-box:hover h3{
    color: #fff;
}

.pricing-box:hover .price-features li{
    color: #eee;
}

.pricing-box:hover{
    background: rgb(29, 28, 28);
    border-color: rgb(29, 28, 28);
}

.pricing-box:hover .btn{
    background: #fff!important;
    color: #000!important;
}



/*-------------------------
 PORTFOLIO
---------------------------*/

.single-work {
    -webkit-transition: all 300ms ease-in-out;
    -o-transition:  all 300ms ease-in-out;
    transition:  all 300ms ease-in-out;
}



.work-block{
    position: relative;
    border: 5px solid#fff;
    
}
.work-block img{
    width: 100%;
    border-radius: 5px;
}

.overlay-content-block a{
    width: 50px;
    height: 50px;
    display: inline-block;
    text-align: center;
    padding-top: 12px;
    border-radius: 100%;
    position: absolute;
    left: 30px;
    top: -23px;
    background: #53C279;
    color: #fff;
}


.overlay-content-block{
    position: absolute;
    content: "";
    bottom: 20px;
    width: 100%;
    left: 30px;
    padding: 30px 20px;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 300ms ease-in-out;
    -o-transition:  all 300ms ease-in-out;
    transition:  all 300ms ease-in-out;
    border-radius: 5px 0px 0px 5px;
}

.work-block:hover .overlay-content-block{
    opacity: 1;
    visibility: visible;
}


.overlay-content-block h4{
    margin-bottom: 0px;
    margin-top: 10px;
}

/*----------------------
 TETSIMONIAL
-------------------------*/

.testimonial .content img{
    margin: 0 auto 30px;
    border: 3px solid#fff;
}

.img-md{
    width: 120px;
    height: 120px;
    border-radius: 100%;
}

/*------------------------
 BLOG
-------------------------*/

.blog-content h6 span{
    display: inline-block;
    padding-left: 10px;
}
.blog-content h6 span:after{
    display: none;
}
.blog-content p{
    margin: 20px 0px;
}




/*-----------------------
 HOME-3
---------------------------*/

.header-white{
    background: #53C279;
    -webkit-box-shadow: 0 7px 22px rgba(0, 0, 0, 0.08);
    box-shadow:0 7px 22px rgba(0, 0, 0, 0.08) ;
    border-radius: 0px;
}

.trans-navigation .navbar-brand h4,.site-navigation .navbar-brand h4,.site-navigation .navbar-brand h3,.trans-navigation .navbar-brand h3{
    color: #fff;
}

.banner-content.style-2{
    padding-top: 200px;
}

.trans-navigation .navbar-brand {
    padding-top: 13px;
}


.site-navigation.header-white {
    border-bottom: 1px solid transparent;
}

.site-navigation .navbar-brand {
    padding-top: 13px;
    margin-right: 20px;
}


.banner-content2{
    padding: 200px 0px;
}

.banner-content2 .display-4{
    color: #fff;
    font-weight: 600;
}

.banner-content2 p.lead{
    color: #fff;
}


/*--------------------------
PAGE BANNER
--------------------------*/

.page-banner-area{
    padding: 180px 0px;
}



.page-banner-content h1{
    color: #fff;
}
.page-banner-content p{
    color: #eee;
}

.form-control{
    border-radius: 0px;
    height: 45px;
}


/*---------------------------
CONTACT PAGE
-----------------------------*/

#contact-info{
    padding-top: 90px;
}
.contact-info-block{
    border: 1px solid#eee;
    padding: 40px 20px;
    border-radius: 5px;
}
.contact-info-block h4{
    margin-bottom: 0px;
}
.contact-info-block i{
    font-size: 40px;
    margin-bottom: 20px;
    display: block;
    color: #53C279;
}

.form-control:focus,.form-control:hover{
    border-color: #53C279;
    box-shadow: none;
}


/* --------------
    MAP
-----------------*/

#map{
    height: 400px;
    width: 100%;
}


/* ==============================
  SINGEL PROJECTS
=============================*/

.project-details-info{
   margin: 40px 0px;
}

.info-block-2{
    float: left;
    margin-right: 65px;
    margin-bottom: 40px;
}


.mt50{
    margin-top: 80px;
}
.project-info ul {
    margin-top: 40px;
}
.project-info ul li{
    margin-bottom: 30px;
    padding-right: 20px;
}

.project-lg-img img {
    width: 100%;
}


/* =========================
PRELOADER
=======================*/

#page-loader {
    background: #fff;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1050; }
  
  .loader-icon {
    background: none repeat scroll 0 0 #fff;
    border-bottom: 3px solid rgba(19, 19, 19, 0.1) !important;
    border-left: 3px solid rgba(19, 19, 19, 0.1) !important;
    border-radius: 100px;
    -moz-border-radius: 100px;
    -webkit-border-radius: 100px;
    -o-border-radius: 100px;
    -ms-border-radius: 100px;
    border-right: 3px solid #1453F2 !important;
    border-top: 3px solid rgba(19, 19, 19, 0.1) !important;
    height: 50px;
    left: 50%;
    margin: -20px 0 0 -20px;
    position: absolute;
    text-align: center;
    top: 50%;
    width: 50px;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
  
  .colored-border {
    border-color: #fff; }
  
  .fa-spin {
    -webkit-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear; }
  
  @-webkit-keyframes fa-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg); } }
  
  @keyframes fa-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg); } }
  
  @media (max-width: 991px) {
    .section-title {
      font-size: 30px; }
    .background-text {
      font-size: 60px; } }
  
  @media (max-width: 576px) {
    .section-title {
      top: 0; }
    .background-text {
      display: none; } }